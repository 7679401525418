<template>
  <div class="home">
    <!-- 一级页面 -->
    <div class="firstCont">
      <!-- <div class="item test">
        <div class="bg">
        </div>
        <span class="span1">可对应选择测试类型进行心理测试。</span>
        <div class="btn" @click="toGauge">
          <span>进入 <i class="el-icon-right"></i></span>
        </div>
        <span class="title span3">
          <img src="../../assets/images/service/logo.png" alt=""> 心理测试</span>
       

      </div> -->
      <div class="item" @click="toCentre(item.id)" v-for="item in firstList" :key="item.id">
        <div class="bg">
        </div>
        <span class="span1">{{ item.text }}</span>
        <div class="btn">
          <span>进入 <i class="el-icon-right"></i></span>
        </div>
        <span class="title span3">{{ item.name }}</span>
        <img :src="require(`../../assets/images/service/${item.imgPath}`)" alt="">

      </div>
      <div class="item addItem" ></div>
    </div>
    <!-- 德慈曼扉，服务设备 -->
    <div class="equipment">
      <div class="titleContent">
        <p class="p1">德慈曼扉，服务设备</p>
        <span class="span1">Service equipment</span>
      </div>
      <div class="equipmentContent">
        <div class="item" v-for="item in equipmentList" :key="item.id">
          <div class="title">
            <span class="span2">{{ item.name }}</span>
          </div>
          <!-- <img src="../../assets/images/service/equipment (1).png" alt=""> -->
          <img :src="require(`../../assets/images/service/${item.imgPath}`)" alt="">
          <p class="p2">
            {{ item.text }}</p>
          <div class="bottomStyle"></div>
        </div>

      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      firstList: [
        { id: 1, imgPath: 'first1.png', name: '成瘾中心', text: '成瘾中心专注于解决各类物质及行为成瘾的问题。' },
        { id: 2, imgPath: 'first2.png', name: '睡眠中心', text: '睡眠中心专注于解决各个年龄层次人群的睡眠问题。' },
        { id: 3, imgPath: 'first3.png', name: '关系中心-亲密关系单元', text: '在亲密关系单元，家庭、婚姻、恋爱、性爱等关系都是我们讨论的范畴。' },
        { id: 4, imgPath: 'first4.png', name: '关系中心-亲子沟通中心', text: '亲子关系单元重点讨论家庭教育，亲子之间的关系，亲子之间的沟通和表达。' },
        { id: 5, imgPath: 'first5.png', name: '关系中心-人际关系中心', text: '在人际关系单元，我们讨论朋友关系、同学关系、师生关系以及职场中的各种关系。' },
        { id: 6, imgPath: 'first6.png', name: '个人成长中心', text: '克己、拖延、习惯养成、动机、完美主义、成长型思维，这些都会在个人成长中心中阅读到。' },
        { id: 7, imgPath: 'first7.png', name: '学习困难中心', text: '学习困难中心专注于解决儿童青少年的学习困难问题。' },
        { id: 8, imgPath: 'first8.png', name: '情绪障碍中心', text: '情绪障碍中心专注于解决各个年龄层次人群的情绪障碍问题。' },
        { id: 9, imgPath: 'first9.png', name: '认知障碍中心', text: '认知障碍中心专注于中老年人群的认知功能障碍问题。' },

      ],
      equipmentList: [
        { id: 1, name: '经颅磁刺激', imgPath: 'equipment (4).png', text: '经颅磁刺激技术是一种利用脉冲磁场作用于中枢神经系统（主要是大脑），改变皮层神经细胞的膜电位，使之产生感应电流，影响脑内代谢和神经电活动，从而引起一系列生理生化反应的磁刺激技术。' },
        { id: 2, name: '心电监护仪', imgPath: 'equipment (2).png', text: '可穿戴心脏贴片的尺寸和重量是市面上其他产品的一半，能够捕获远程患者的多个生命体征并将其发送到云中的应用程序。易于使用的贴片可重复使用和充电，患者可以在家中自行涂抹。' },
        { id: 3, name: '多功能失眠治疗仪', imgPath: 'equipment (3).png', text: '量表评估+实时脑电+治疗报告，睡眠医学中心必备。实现脑波诱导、体感震动、音乐治疗、催眠放松治疗技术的完美融合，提供绿色、安全的治疗方案，通过音乐理疗，缓解亚健康人群压力状态以及相应的心身问题。' },
        { id: 4, name: '光照治疗系统', imgPath: 'equipment (1).png', text: '光照治疗系统的核心是针对不同病症，通过不同波长的参数滴定，通过非视觉神经通路传递到视交叉上核，从而通过调节生物节律改善临床症状，利于疗养神经。 ' }
      ]
    }
  },
  methods: {
    //跳转到量表
    toGauge() {
      this.$router.push('/service/gauge')
    },
    toCentre(val) {
      sessionStorage.setItem('centreId', val);
      this.$router.push('/service/centre');
      
      // this.$router.push({
      //   path: '/service/centre',
      //   query:{
      //     id: val
      //   }
      // })
    }
  }
}
</script>

<style lang="scss" scoped>
.home {
  // height: 100%;
  .firstCont {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    width: 100%;
    padding-top: 20px;
    
    // text-align: center;
    .item {
      // margin:0 auto;
      // display: inline-block;
      // width: 374px;
      width: 208px;
      height: 158px;
      // margin-right: 60px;
      // background: #000;
      margin: 0 20px;
      margin-top: 20px;
      position: relative;
      cursor: pointer;

      &:hover {
        box-shadow: 2px 2px 4px 0px rgba(36, 19, 3, 0.75);

        .title {
          top: 0px;
        }

        .bg,
        .span1,
        .btn {
          display: block;
        }
      }

      .bg,
      .btn,
      .span1 {
        position: absolute;
      }

      .span1 {
        box-sizing: border-box;
        display: none;
        width: 100%;
        padding: 45px 20px;
        color: #FFFFFF;
      }

      .btn {
        display: none;
        width: 100%;
        text-align: center;
        bottom: 8px;

        span {
          margin: auto;
          display: block;
          font-size: 15px;
          font-weight: 600;
          color: var(--headFont-color);
          width: 169px;
          height: 30px;
          line-height: 30px;
          background: var(--custom-color);

          i {
            font-size: 15px;
            font-weight: 600;
          }
        }
      }

      .bg {
        height: 100%;
        width: 100%;
        background: #000000;
        opacity: 0.2;
        display: none;
        
      }

      .title {
        display: inline-block;
        top: 60px;
        width: 100%;
        background: #FFFFFF;
        opacity: 0.7;
        position: absolute;
        font-weight: 600;
        color: var(--headFont-color);
        line-height: 22px;
        height: 40px;
        display: flex;
        align-items: center;
        justify-content: center;
        // line-height: 40px;
        // text-align: center;
      }

      img {
        height: 100%;
        width: 100%;
        margin: 0;
        padding: 0;
      }
    }
    .addItem{
      display: none;
      &:hover{
        box-shadow: none;
      }
    }
    .test {
      background-color: var(--custom-color);

      img {
        width: 21px;
      }

      .span1 {
        color: var(--headFont-color);
      }

      .btn {
        span {
          background: #FFFFFF;
          color: var(--headFont-color);
        }
      }

      &:hover {
        .bg {
          display: none;
        }
      }
    }
  }

  .equipment {
    .equipmentContent {
      // text-align: center;
      display: flex;
      flex-wrap: wrap;
      justify-content: space-evenly;
      .item {
        display: inline-block;
        width: 268px;
        // margin: 5px 3px;
        border-bottom: 1px solid #D8D8D8;
        position: relative;
        margin-bottom: 10px;

        &:hover {
          .bottomStyle {
            display: block;
          }
        }

        .title {
          width: 100%;
          height: 40px;
          background: #E5E5E5;
          text-align: center;

          span {
            line-height: 40px;
            color: var(--titleFont-color);
          }
        }

        img {
          width: 100%;
          // height: 162px;
        }

        p {
          padding-top: 10px;
          padding-bottom: 20px;
          color: var(--memoFont-color);
          line-height: 23px;
        }
      }

    }
  }
}
@media only screen and (min-width : 1150px){
  .home{
    .firstCont {
      justify-content: start;
      .item{
        margin: 0px;
        margin-top: 20px;
        margin-right: 10px;
      }
    }
    
  }
}
@media only screen and (max-width : 500px){
  .home{
    .firstCont {
      justify-content: space-evenly;
      .item{
        width: 168px;
        height: 120px;
        margin: 0px ;
        margin-top: 20px;

        // margin-right: 10px;
      }
      .addItem{
        display: block;
        
      }
    }
     .equipment .equipmentContent{
      flex-wrap: nowrap;
      justify-content: flex-start;
      overflow: hidden;
      overflow-x: scroll;

      .item{
        flex-shrink: 0;
        margin-right: 20px;
      }
     }
  }
}
// @media only screen and (max-width : 600px)
</style>